.responseTabsRoot {
	background-color: #2d2d2d;
}
.responseEditor {
	width: 100%;
}
.Response {
	margin-top: 2rem;
}
.responseLoading {
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

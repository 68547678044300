.RequestBar {
	display: flex;
	flex: 1;
	margin: 0.5rem;
	@media (max-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "method send" "url url";
		grid-gap: 1rem;
	}
}

.requestMethod {
	min-width: 10rem !important;
	@media (max-width: 768px) {
		grid-area: method;
	}
}

.requestUrl {
	flex: 1;
	margin: 0 1rem !important;
	@media (max-width: 768px) {
		grid-area: url;
		margin: 0 !important;
	}
}

.requestSend {
	@media (max-width: 768px) {
		grid-area: send;
	}
}

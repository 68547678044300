.responseDetailsRoot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1rem;
}
.responseDetails {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.responseDetail {
	margin: 0 0.5rem;
}

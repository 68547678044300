.requestTabsRoot {
	padding: initial;
}
.requestBodyEditor {
	width: 100%;
}
.requestBodyResponse {
	width: 100%;
}
.requestHeaderRoot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}

.headersRoot {
	padding: 0.25rem;
}

.keyValue {
	display: flex;
	align-items: center;
}

.key {
	flex: 1;
	margin: 0.25rem !important;
}

.value {
	flex: 1;
	margin: 0.25rem !important;
}

.removeIcon {
	width: 3rem;
	height: 3rem;
}

.paramsRoot {
	padding: 0.25rem;
}

.paramsKeyValue {
	display: flex;
	align-items: center;
}

.paramsKey {
	flex: 1;
	margin: 0.25rem !important;
}

.paramsValue {
	flex: 1;
	margin: 0.25rem !important;
}

.paramsRemoveIcon {
	width: 3rem;
	height: 3rem;
}
